import {
  EmptyStateResult,
  EmptyStateResultProps
} from '@komo-tech/ui/Result/Empty';
import { FC } from 'react';

import { UndrawImages } from '@/common/utils/UndrawImages';

export const PortalError: FC<EmptyStateResultProps> = ({
  flex = '1 1 auto',
  bg = 'var(--mantine-color-body)',
  imageWidth = 300,
  image = UndrawImages.server,
  children,
  ...rest
}) => {
  return (
    <EmptyStateResult
      flex={flex}
      bg={bg}
      image={image}
      imageWidth={imageWidth}
      {...rest}
    >
      {children}
    </EmptyStateResult>
  );
};

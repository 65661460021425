import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

const undrawUrl = (name: string) => {
  return `/assets/images/undraw-${name}.svg`;
};

export const UndrawImages = {
  addContent: new ImageDataModel({
    url: undrawUrl('add-content'),
    alt: 'Add Content'
  }),
  addPage: new ImageDataModel({
    url: undrawUrl('add-page'),
    alt: 'Add Page'
  }),
  analysis: new ImageDataModel({
    url: undrawUrl('analysis'),
    alt: 'Analysis'
  }),
  analytics: new ImageDataModel({
    url: undrawUrl('analytics'),
    alt: 'Analytics'
  }),
  blankCanvas: new ImageDataModel({
    url: undrawUrl('blank-canvas'),
    alt: 'Blank Canvas'
  }),
  buildingBlocks: new ImageDataModel({
    url: undrawUrl('building-blocks'),
    alt: 'Building Blocks'
  }),
  calendar: new ImageDataModel({
    url: undrawUrl('calendar'),
    alt: 'Calendar'
  }),
  cancel: new ImageDataModel({
    url: undrawUrl('cancel'),
    alt: 'Cancel'
  }),
  choice: new ImageDataModel({
    url: undrawUrl('choice'),
    alt: 'Choice'
  }),
  chooseColor: new ImageDataModel({
    url: undrawUrl('choose-color'),
    alt: 'Choose Color'
  }),
  cardPostal: new ImageDataModel({
    url: undrawUrl('card-postal'),
    alt: 'Card Postal'
  }),
  communications: new ImageDataModel({
    url: undrawUrl('communications'),
    alt: 'Communications'
  }),
  companyMembers: new ImageDataModel({
    url: undrawUrl('company-members'),
    alt: 'Company Members'
  }),
  content: new ImageDataModel({
    url: undrawUrl('content'),
    alt: 'Content'
  }),
  completedTasks: new ImageDataModel({
    url: undrawUrl('completed-tasks'),
    alt: 'Completed Tasks'
  }),
  coupons: new ImageDataModel({
    url: undrawUrl('coupons'),
    alt: 'Coupons'
  }),
  data: new ImageDataModel({
    url: undrawUrl('data'),
    alt: 'Data'
  }),
  dataLists: new ImageDataModel({
    url: undrawUrl('data-lists'),
    alt: 'Data Lists'
  }),
  dataProcessing: new ImageDataModel({
    url: undrawUrl('data-processing'),
    alt: 'Data Processing'
  }),
  domainNames: new ImageDataModel({
    url: undrawUrl('domain-names'),
    alt: 'Domain Names'
  }),
  droneRrace: new ImageDataModel({
    url: undrawUrl('drone-race'),
    alt: 'Drone Race'
  }),
  dropdownMenu: new ImageDataModel({
    url: undrawUrl('dropdown-menu'),
    alt: 'Dropdown Menu'
  }),
  editable: new ImageDataModel({
    url: undrawUrl('editable'),
    alt: 'Editable'
  }),
  filter: new ImageDataModel({
    url: undrawUrl('filter'),
    alt: 'Filter'
  }),
  forms: new ImageDataModel({
    url: undrawUrl('forms'),
    alt: 'Forms'
  }),
  giftCards: new ImageDataModel({
    url: undrawUrl('gift-card'),
    alt: 'Gift card'
  }),
  emailCampaign: new ImageDataModel({
    url: undrawUrl('email-campaign'),
    alt: 'Email Campaign'
  }),
  emailSubscribe: new ImageDataModel({
    url: undrawUrl('email-subscribe'),
    alt: 'Email Subscribe'
  }),
  empty: new ImageDataModel({
    // blurHash: 'LtOgKR%M~qIVRj%Lt7M{-;M{M{xu',
    url: undrawUrl('empty'),
    alt: 'Empty'
    // height: 834.5,
    // width: 797.5
  }),
  features: new ImageDataModel({
    url: undrawUrl('features'),
    alt: 'Features'
  }),
  fileSync: new ImageDataModel({
    url: undrawUrl('file-sync'),
    alt: 'File Sync'
  }),
  fonts: new ImageDataModel({
    url: undrawUrl('fonts'),
    alt: 'Fonts'
  }),
  growthAnalytics: new ImageDataModel({
    url: undrawUrl('growth-analytics'),
    alt: 'Growth Analytics'
  }),
  investment: new ImageDataModel({
    url: undrawUrl('investment'),
    alt: 'Investment'
  }),
  liveCollaboration: new ImageDataModel({
    url: undrawUrl('live-collaboration'),
    alt: 'Live Collaboration'
  }),
  location: new ImageDataModel({
    url: undrawUrl('location'),
    alt: 'Location'
  }),
  mention: new ImageDataModel({
    url: undrawUrl('mention'),
    alt: 'Mention'
  }),
  mindMap: new ImageDataModel({
    url: undrawUrl('mind-map'),
    alt: 'Mind Map'
  }),
  myAnswer: new ImageDataModel({
    url: undrawUrl('my-answer'),
    alt: 'My Answer'
  }),
  newEntries: new ImageDataModel({
    blurHash: 'LASY~#?c_24T%OIVRi-:~pIU9H.8',
    url: undrawUrl('new-entries'),
    alt: 'New Entries',
    height: 641.9894,
    width: 924.76626
  }),
  notes: new ImageDataModel({
    url: undrawUrl('notes'),
    alt: 'Notes'
  }),
  notFound: new ImageDataModel({
    url: undrawUrl('not-found'),
    alt: 'Not Found'
  }),
  onlineConnection: new ImageDataModel({
    url: undrawUrl('online-connection'),
    alt: 'Online Connection'
  }),
  palette: new ImageDataModel({
    url: undrawUrl('palette'),
    alt: 'Palette'
  }),
  payments: new ImageDataModel({
    url: undrawUrl('payments'),
    alt: 'Payments'
  }),
  people: new ImageDataModel({
    url: undrawUrl('people'),
    alt: 'People'
  }),
  percentages: new ImageDataModel({
    url: undrawUrl('percentages'),
    alt: 'Percentages'
  }),
  plans: new ImageDataModel({
    url: undrawUrl('plans'),
    alt: 'Plans'
  }),
  preferencesPopup: new ImageDataModel({
    url: undrawUrl('preferences-popup'),
    alt: 'Preferences Popup'
  }),
  prizes: new ImageDataModel({
    url: undrawUrl('prizes'),
    alt: 'Prizes'
  }),
  publish: new ImageDataModel({
    url: undrawUrl('publish'),
    alt: 'Publish'
  }),
  questions: new ImageDataModel({
    url: undrawUrl('questions'),
    alt: 'Questions'
  }),
  results: new ImageDataModel({
    url: undrawUrl('results'),
    alt: 'Results'
  }),
  security: new ImageDataModel({
    url: undrawUrl('security'),
    alt: 'Security'
  }),
  searching: new ImageDataModel({
    url: undrawUrl('searching'),
    alt: 'Searching'
  }),
  searchFiles: new ImageDataModel({
    url: undrawUrl('search-files'),
    alt: 'Search Files'
  }),
  segments: new ImageDataModel({
    url: undrawUrl('segments'),
    alt: 'Segments'
  }),
  server: new ImageDataModel({
    url: undrawUrl('server'),
    alt: 'Server'
  }),
  serverPush: new ImageDataModel({
    url: undrawUrl('server-push'),
    alt: 'Server Push'
  }),
  signup: new ImageDataModel({
    url: undrawUrl('signup'),
    alt: 'Signup'
  }),
  sitesEmpty: new ImageDataModel({
    url: undrawUrl('sites-empty'),
    alt: 'Sites Empty'
  }),
  sitesEmptyChristmas: new ImageDataModel({
    url: undrawUrl('sites-empty-christmas'),
    alt: 'Sites Empty Christmas'
  }),
  siteStats: new ImageDataModel({
    url: undrawUrl('site-stats'),
    alt: 'Site Stats'
  }),
  subscriptions: new ImageDataModel({
    url: undrawUrl('subscriptions'),
    alt: 'Subscriptions'
  }),
  tasks: new ImageDataModel({
    url: undrawUrl('tasks'),
    alt: 'Tasks'
  }),
  team: new ImageDataModel({
    url: undrawUrl('team'),
    alt: 'Team'
  }),
  terms: new ImageDataModel({
    url: undrawUrl('terms'),
    alt: 'Terms'
  }),
  testing: new ImageDataModel({
    url: undrawUrl('testing'),
    alt: 'Testing'
  }),
  texting: new ImageDataModel({
    url: undrawUrl('texting'),
    alt: 'Texting'
  }),
  upload: new ImageDataModel({
    url: undrawUrl('upload'),
    alt: 'Upload'
  }),
  underConstruction: new ImageDataModel({
    url: undrawUrl('under-construction'),
    alt: 'Under Construction'
  }),
  websiteSetup: new ImageDataModel({
    url: undrawUrl('website-setup'),
    alt: 'Website Setup'
  }),
  selection: new ImageDataModel({
    url: undrawUrl('selection'),
    alt: 'Selection'
  }),
  openedEmail: new ImageDataModel({
    url: undrawUrl('opened-email'),
    alt: 'Opened Email'
  })
};

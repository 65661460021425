'use client';
import type { ImageProps as MantineImageProps } from '@mantine/core';
import {
  Image as MantineImage,
  ImageFactory as MantineImageFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type ImageProps = MantineImageProps;
type ImageReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineImageFactory>
>;
export const Image = MantineImage as ImageReturnType;
